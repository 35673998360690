import React from "react"

import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { Link, withPrefix } from "gatsby"
import { changeProgressBarOnScroll } from "../components/utils/index"

export default class BlogPostPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "FAQ")
  }

  render() {
    return (
      <Layout>
        <div className="post-page"></div>
      </Layout>
    )
  }
}
